import { render, staticRenderFns } from "./keyboard.vue?vue&type=template&id=c8f494b4&scoped=true&"
import script from "./keyboard.vue?vue&type=script&lang=js&"
export * from "./keyboard.vue?vue&type=script&lang=js&"
import style0 from "./keyboard.vue?vue&type=style&index=0&lang=css&"
import style1 from "./keyboard.vue?vue&type=style&index=1&id=c8f494b4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8f494b4",
  null
  
)

export default component.exports