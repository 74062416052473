<template>
    <div class="h-100 mt-2">
        <b-tabs pills>
            <b-tab v-for="cat in categories" v-bind:key="cat.name">
                <template slot="title">
                    {{ cat.name }}
                    <b-badge pill v-if="countItems(cat) > 0" variant="secondary" class="ml-1">{{ countItems(cat) }}</b-badge>
                </template>
                <kc-shopping-card :category="cat.name" v-model="items" :proposalItems="proposalItems"/>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>
const STORAGE_VAR_CATEGORIES = 'kc.shoppingCategories';
const STORAGE_VAR_PROPOSALS = 'kc.shoppingCardProposals';
const STORAGE_VAR_ITEMS = 'kc.shoppingCardItems';
import kcShoppingCard from './shopping-card.vue';
export default {
    name: 'kc-shopping-cards',
    components: {
        kcShoppingCard
    },
    data() {
        return {      
            defaultCategories: [
                { name: 'Aldi' },
                { name: 'Edeka' },
                { name: 'DM' },
                { name: 'Denn´s' },
                { name: 'FilMea' }
            ],
            proposalItems: [],
            items: []   
        }
    },
    computed: {
        categories() {
            const vm = this;
            if (localStorage[STORAGE_VAR_CATEGORIES] === undefined) {
                localStorage[STORAGE_VAR_CATEGORIES] = JSON.stringify(vm.defaultCategories);
            }
            var categories = JSON.parse(localStorage[STORAGE_VAR_CATEGORIES]);
            return categories;
        }
    },
    watch: {
        items: {
            deep: true,
            handler() {
                const vm = this;
                localStorage[STORAGE_VAR_ITEMS] = JSON.stringify(vm.items);
            }
        },
        proposalItems: {
            deep: true,
            handler() {
                const vm = this;
                localStorage[STORAGE_VAR_PROPOSALS] = JSON.stringify(vm.proposalItems);
            }
        }        
    },
    created() {
        const vm = this;
        var storeProposals = localStorage[STORAGE_VAR_PROPOSALS];
        if (storeProposals !== undefined) {
            vm.proposalItems = JSON.parse(storeProposals);
        }        
        var storeItems = localStorage[STORAGE_VAR_ITEMS];
        if (storeItems !== undefined) {
            vm.items = JSON.parse(storeItems);
        }                
    },
    methods: {
        countItems(category) {
            const vm = this;            
            return vm.items.filter(x => x.category === category.name).length;
        }
    }
}
</script>
<style lang="sass" scoped>
</style>