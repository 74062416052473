<template>
    <b-container fluid class="h-100 pl-0 pr-0">
        <b-row v-for="t in timer" v-bind:key="t.id">
            <b-col>
                <kc-timer v-model="t.timerData" @remove="removeTimer(t)"/>
            </b-col>
        </b-row>
        <b-row v-if="timer.length < 5">
            <b-col class="text-center">
                <b-button variant="primary" @click="addTimer" size="lg" class="mt-2"><b-icon icon="plus"/> Timer hinzufügen</b-button>
            </b-col>
        </b-row>            
    </b-container>
</template>
<script>
const STORAGE_VAR_TIMERS = 'kc.timers';
import kcTimer from './timer.vue';
export default {
    name: 'kc-clock',
    components: {
        kcTimer
    },
    data() {
        return {   
            timer: []         
        }
    },
    props: {
        withDigits: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        time() {
            const vm = this;
            return vm.$moment().format('HH:mm');            
        }
    },
    watch: {
        timers: {
            deep: true,
            handler() {
                const vm = this;
                localStorage[STORAGE_VAR_TIMERS] = JSON.stringify(vm.timers);
            }
        }
    }, 
    methods: {
        addTimer() {
            const vm = this;
            var t = {
                id: vm.newId(),
                timerData: {
                    text: 'Mein Timer',
                    startTime: 420,
                    timer: 420
                }
            }
            vm.timer.push(t);
        },
        removeTimer(t) {
            const vm = this;
            var index = vm.timer.indexOf(t);
            if (index > -1) {
                vm.timer.splice(index, 1);
            }
        },
        newId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }        
    }
}
</script>
<style lang="sass" scoped>
</style>