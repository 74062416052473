<template>
  <div>
    <textarea :class="className" v-if="options.type === 'textarea'" ref="keyboardRef"/>
    <input :class="className + ' border border-dark rounded w-100 p-2'" v-else ref="keyboardRef"/>
  </div>
</template>

<style>
  @import "./keyboard.css";
</style>

<script>
import jQuery from 'jquery'
import 'virtual-keyboard/dist/js/jquery.keyboard.js'
export default {
  props: {
    value: String,
    debug: Boolean,
    autoAccept: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      options: {
        usePreview: false,
        stickyShift: false,
        language: 'de',
        layout: 'german-qwertz',
        display: {
          accept: 'OK',
          bksp: 'Del',
          cancel: 'Abbruch',
          enter: 'Enter',
        },
      },
      unwatch: null,
      className: 'keyboard-wrapper'
    }
  },
  created() {
    jQuery.keyboard.layouts['german-qwertz'] = {
      'name' : 'German-qwertz',
      'lang' : ['de'],
      'default' : [
        "^ 1 2 3 4 5 6 7 8 9 0 ß ´ {bksp}",
        "{tab} q w e r t z u i o p ü +",
        "a s d f g h j k l ö ä # {enter}",
        "{shift} < y x c v b n m , . - {shift}",
        "{accept} {alt} {space} {alt} {cancel}"
      ],
      'shift' : [
        '° ! " § $ % & / ( ) = ? ` {bksp}',
        "{tab} Q W E R T Z U I O P Ü *",
        "A S D F G H J K L Ö Ä ' {enter}",
        "{shift} > Y X C V B N M ; : _ {shift}",
        "{accept} {alt} {space} {alt} {cancel}"
      ]
    };
  },
  mounted () {
    const that = this
    this.options.accepted = (event, keyboard, el) => {
      that.handleChange('', el.value)
      that.$emit('onAccepted', el.value)
      if (that.debug) {
        console.log('The content "' + el.value + '" was accepted')
      }
    }
    this.options.visible = () => {
      this.className = 'keyboard-wrapper open'
    }
    this.options.hidden = () => {
      this.className = 'keyboard-wrapper'
    }
    // Set Value to Input Element on Change if prop set
    if (this.options.updateOnChange === true) {
      this.options.change = (event, keyboard, el) => {
        this.handleChange('', keyboard.preview.value)
        if (this.debug) {
          console.log('The content "' + el.value + '" was changed')
        }
      }
    }

    this.addKeyBoardToDOM()

		// Update while typing if usePreview is false
    if (this.options.usePreview === false) {
      jQuery(this.$refs.keyboardRef).on('keyboardChange', (event, keyboard) => {
        that.handleChange(null, keyboard.preview.value)
      })
    }

    // set default value
    jQuery(this.$refs.keyboardRef).val(this.value)

   // bind value
    this.unwatch = this.$watch('value', (newValue) => {
      jQuery(this.$refs.keyboardRef).val(newValue)
    })
  },
  beforeDestroy () {
    if (this.unwatch) this.unwatch()
    jQuery(this.$refs.keyboardRef).remove()
  },
  methods: {
    addKeyBoardToDOM () {
      const keyboardSelector = jQuery(this.$refs.keyboardRef)
      keyboardSelector.keyboard(this.options)
      /**
       * Get instantiated keyboard
       */
      this.keyboard = keyboardSelector.getkeyboard()
    },
    clear () {
      var keyboard = jQuery(this.$refs.keyboardRef)
      keyboard.val('')
      this.handleChange('', '')
    },
    select () {
      this.$refs.keyboardRef.select()
    },
    blur () {
      var keyboard = jQuery(this.$refs.keyboardRef)
      if (keyboard && typeof keyboard.getkeyboard === 'function' && typeof keyboard.getkeyboard().close === 'function') { keyboard.getkeyboard().close() }
      this.$refs.keyboardRef.blur()
    },
    checkValidity () {
      return this.$refs.keyboardRef.checkValidity()
    },
    handleChange (event, input) {
      if (!input && event && event.target && typeof event.target.value !== 'undefined') { input = event.target.value }
      if (this.debug) {
        console.log('Change', input)
      }
      this.$emit('input', input)
      this.$emit('onChange', input)
    }
  }
}
</script>
<style scoped>
    input {
        border: none;
    }

    textarea:focus, input:focus{
        border: none;
        outline: none;
    }
</style>