<template>
    <div class="h-100 mt-2 border border-primary border-top-1 border-left-0 border-right-0 border-bottom-0">
        <b-container v-if="viewMode === 'list'" fluid>
            <b-row>
                <b-col class="p-0">
                    <b-button-toolbar class="mt-2">
                        <b-button variant="primary" size="lg" @click="showSelection"><b-icon icon="plus-circle"/> Eintrag hinzufügen</b-button>
                        <b-button v-if="categoryItems.length > 0" variant="dark" size="lg" class="ml-2" @click="emptyConfirm"><b-icon icon="x-circle"/> Liste {{ category }} leeren</b-button>                        
                        <b-button v-if="categoryItems.length > 0" variant="secondary" size="lg" class="ml-2" @click="sendCart('hartmann.nicole@outlook.de')"><b-icon icon="envelope"/> Nicole</b-button>
                        <b-button v-if="categoryItems.length > 0" variant="secondary" size="lg" class="ml-2" @click="sendCart('hartmann@ndg.de')"><b-icon icon="envelope"/> Sven</b-button>                        
                    </b-button-toolbar>
                    <div v-if="sending" class="text-center p-3 small">
                        <span><b-spinner/> Einkaufsliste wird per E-Mail versendet...</span>
                    </div>
                    <b-alert v-model="showSendSuccess" variant="success" dismissible class="mt-2 mb-0 small">
                        <span><b-icon icon="check-circle"/> E-Mail wurde erfolgreich versendet.</span>
                    </b-alert>
                    <b-alert v-model="showSendError" show variant="danger" dismissible class="mt-2 mb-0 small">
                        <span><b-icon icon="exclamation-triangle"/> E-Mail konnte nicht versendet werden: {{ sendError }}</span>
                    </b-alert>                    
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col cols="12" v-if="items.length === 0" class="text-center mt-2">
                    <b-icon icon="info-circle"/><br/>
                    <span>Keine Einträge in der Einkaufsliste <span class="font-weight-bold">{{ category }}</span>.</span>
                </b-col>  
                <b-col cols="*" v-for="item in categoryItems" v-bind:key="item.id">
                    <div class="bg-light text-dark border border-dark rounded p-2 mr-2 mb-2">
                        {{ item.text }} <b-button variant="danger" @click="delConfirm(item)" class="ml-2"><b-icon icon="x"/></b-button>
                    </div>
                </b-col>
            </b-row>    
        </b-container>      
        <kc-confirm v-if="item !== null" ref="confirmDelete" v-model="deleteConfirmation" @ok="del">
            <span>Möchten Sie den Eintrag <span class="font-weight-bold">{{ item.text }}</span> von der Einkaufsliste <span class="font-weight-bold">{{ category }}</span> entfernen?</span>
        </kc-confirm>
        <kc-confirm ref="confirmEmpty" v-model="emptyConfirmation" @ok="empty">
            <span>Möchten Sie die Einkaufsliste <span class="font-weight-bold">{{ category }}</span> komplett leeren?</span>
        </kc-confirm>  
        <b-container v-if="viewMode === 'selection' || viewMode === 'add'">            
            <b-container v-if="viewMode === 'selection'">
                <b-row>
                    <b-col>
                        <h2>Eintrag auswählen zum Hinzufügen</h2>
                    </b-col>
                </b-row>
                <b-row class="ml-0 mt-2">
                    <b-col cols="*" class="mr-2">
                        <b-button variant="primary" size="lg" class="p-2 mb-2" @click="viewMode = 'list'"><b-icon icon="arrow-left-circle"/></b-button>
                    </b-col>
                    <b-col v-for="item in proposalItemsToSelect" v-bind:key="item.id" cols="*" class="mr-2">
                        <b-button variant="dark" size="lg" class="p-2 mb-2" @click="addProposal(item)">
                            {{ item.text }}
                        </b-button>
                    </b-col>
                    <b-col cols="*" class="mr-2">
                        <b-button variant="primary" size="lg" class="p-2 mb-2" @click="showAdd"><b-icon icon="plus-circle"/> Neuen Eintrag erstellen</b-button>
                    </b-col>                    
                </b-row>
            </b-container>
            <b-container v-if="item !== null && viewMode === 'add'" fluid class="bg-light p-3 mt-2 border border-dark rounded">
                <b-row class="bg-light mt-2">
                    <b-col>
                        <h2>Neuen Eintrag erstellen</h2>                        
                    </b-col>
                </b-row>                                               
                <b-row class="ml-0 mr-0 pt-2 align-items-center">
                    <b-col><label for="bezeichnung">Bezeichnung:</label></b-col>
                    <b-col>
                        <kc-keyboard v-model="item.text"/>
                    </b-col>
                </b-row>
                <b-row class="ml-0 mr-0 align-items-center">
                    <b-col><label for="text">Merken für die Zukunft:</label></b-col>
                    <b-col><b-form-checkbox id="store" v-model="item.store" switch size="lg"/></b-col>
                </b-row>                                                
                <b-row v-if="item.store === true" class="ml-0 mr-0 align-items-center">
                    <b-col><label for="text">Nur speichern für {{ category }}:</label></b-col>
                    <b-col><b-form-checkbox id="storeCategory" v-model="item.storeCategory" switch size="lg"/></b-col>
                </b-row>  
                <b-row v-if="validationErrors.length > 0">
                    <b-col>
                        <b-alert show variant="warning" class="small">
                            <b-row>
                                <b-col cols="*">
                                    <b-icon icon="exclamation-triangle"/>
                                </b-col>
                                <b-col>
                                    <div v-for="e in validationErrors" v-bind:key="e">{{ e }}</div>
                                </b-col>
                            </b-row>                 
                        </b-alert> 
                    </b-col>
                </b-row>                               
                <b-row class="align-items-center mt-2">
                    <b-col class="text-center">
                        <b-button variant="dark" size="lg" @click="cancelAdd"><b-icon icon="x"/> Abbruch</b-button>
                    </b-col>
                    <b-col class="text-center">
                        <b-button variant="primary" size="lg" @click="addNewItem" :disabled="validationErrors.length > 0"><b-icon icon="check"/> Eintrag hinzufügen</b-button>
                    </b-col>
                </b-row>                               
            </b-container>     
        </b-container>      
    </div>
</template>
<script>
import kcConfirm from './confirm.vue';
import kcKeyboard from './keyboard.vue';
export default {
    name: 'kc-shopping-card',
    components: {
        kcConfirm,
        kcKeyboard
    },
    data() {
        return {            
            item: null,      
            filter: null,
            deleteConfirmation: false,
            emptyConfirmation: false,
            viewMode: 'list',
            showSendSuccess: false,
            showSendError: false,
            sendError: null,       
            sending: false
        }
    },
    props: {
        category: {
            type: String,
            required: true
        },
        proposalItems: {
            type: Array,
            required: true
        },
        value: null
    },
    computed: {
        items: {
            get() {
                const vm = this;
                return vm.value;
            },
            set(v) {
                const vm = this;
                vm.$emit('input', v);
            }
        },
        categoryItems() {
            const vm = this;
            return vm.items.filter(x => x.category === vm.category);
        },
        categoryProposals() {
            const vm = this;
            return vm.proposalItems.filter(x => x.category === vm.category || x.category === undefined || x.category === null);
        },        
        proposalItemsToSelect() {
            const vm = this;
            var items = vm.categoryProposals.filter(x => {
                var existingItem = vm.categoryItems.find(i => i.text === x.text);
                if (existingItem === undefined) return true;
                return false;
            });
            if (vm.filter !== null && vm.filter !== '') {
                items = items.filter(x => x.text.toLowerCase().includes(vm.filter.toLowerCase()));
            }
            return items;
        },
        isItemValid() {
            const vm = this;
            return (vm.validationErrors.length === 0);
        },
        validationErrors() {
            const vm = this;
            var errors = [];
            if (vm.item === null) {
                errors.push("Kein Eintrag ermittelt.");
                return errors;
            }
            if (vm.item.text === null || vm.item.text === '') {
                return errors;
            }
            var existingItem = vm.categoryItems.find(x => x.text.toLowerCase() === vm.item.text.toLowerCase());
            if (existingItem !== undefined) {
                errors.push("Dieser Eintrag existiert bereits auf der Einkaufsliste.");
            }
            return errors;
        }
    },
    methods: {
        showSelection() {
            const vm = this;
            if (vm.proposalItemsToSelect.length > 0) {
                vm.viewMode = 'selection'
            } else {
                vm.showAdd();
            }
        },
        sendCart(emailAddr) {
            const vm = this;
            vm.showSendSuccess = false;
            vm.showSendError = false;
            vm.sendError = null;
            vm.sending = true;
            var subject = 'Einkaufsliste ' + vm.category;            
            var body = "";
            vm.categoryItems.forEach(i => {
                body += i.text + "\n";
            }); 
            var email = '';
            email += "To: " + emailAddr + "\r\n";
            email += "Subject: " + subject + "\r\n";
            email += "\r\n" + body;
            var resource = {
                'raw': window.btoa(email).replace(/\+/g, '-').replace(/\//g, '_')
            };
            vm.$gapi.getGapiClient().then((gapi) => {
                gapi.client.gmail.users.messages.send({
                    userId: 'me',
                    resource: resource
                })
                .then(() => {
                    vm.sending = false;
                    vm.showSendSuccess = true;                  
                })
                .catch(response => {
                    vm.sending = false;
                    vm.showSendError = true;
                    vm.sendError = response.result.error.message;
                });                
            });
        },
        showAdd() {
            const vm = this;
            vm.viewMode = 'add';
            vm.item = vm.createNewItem('', false);
        },
        cancelAdd() {
            const vm = this;
            vm.item = null;
            if (vm.proposalItemsToSelect.length > 0) {
                vm.viewMode = 'selection';
            } else {
                vm.viewMode = 'list';
            }
        },
        delConfirm(item) {
            const vm = this;
            vm.item = item;
            vm.deleteConfirmation = true;
        },
        del() {
            const vm = this;
            if (vm.item === null) return;
            const index = vm.items.indexOf(vm.item);
            if (index > -1) {
                vm.items.splice(index, 1);
            }
        },
        emptyConfirm() {
            const vm = this;
            vm.emptyConfirmation = true;
        },
        empty() {
            const vm = this;
            var deleteItems = vm.items.filter(x => x.category === vm.category);
            vm.items = vm.items.filter(x => !deleteItems.includes(x));
        },     
        addNewItem() {
            const vm = this;
            if (vm.validationErrors.length > 0) {
                return;
            }
            vm.items.push(vm.item);
            if (vm.item.store === true) {
                var proposalCategory = null;
                if (vm.item.storeCategory === true) {
                    proposalCategory = vm.category;
                }
                vm.proposalItems.push({
                    text: vm.item.text,
                    category: proposalCategory
                });
            }
            vm.item = null;
            vm.viewMode = 'list';
        },
        addProposal(item) {
            const vm = this;
            vm.items.push(vm.createNewItem(item.text, false));
            vm.viewMode = 'list';
        },
        newId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        createNewItem(text, storeAsProposal) {
            const vm = this;
            return {
                id: vm.newId(),          
                text: text,
                category: vm.category,
                store: storeAsProposal,
                storeCategory: false
            };            
        }
    }
}
</script>
<style lang="sass" scoped>
</style>