<template>
    <div>
        <b-container v-if="!loggedIn" fluid class="text-center">
            <img src="../assets/picnic_logo.png" alt="PICNIC" class="mt-3" style="width: 15rem; height: 15rem;"/>
            <b-alert show variant="info" class="mt-2">
                <span><b-icon icon="info-circle"/> PICNIC-Anbindung noch nicht freigeschaltet.</span>
            </b-alert>
            <kc-keyboard v-show="false"/>
        </b-container>
    </div>
</template>
<script>
import PicnicClient, { CountryCodes } from "picnic-api";
import kcKeyboard from './keyboard.vue';
export default {
    name: 'kc-picnic',
    components: {
        kcKeyboard
    },
    data() {
        return {       
            client: null,     
            loggedIn: false,
            email: 'svenh1310@gmail.com',
            password: '3milN!cole2016'
        }        
    },
    created() {
        const vm = this;
        vm.client = new PicnicClient({
            countryCode: CountryCodes.DE, // The country code for the requests.
            apiVersion: "17",
            authKey: null,
            url: "https://storefront-prod.nl.picnicinternational.com/api/17"
        });        
    },
    mounted() {
        const vm = this;
        vm.login();
    },
    methods: {
        login() {
            const vm = this;
            vm.client.login(vm.email, vm.password)
            .then(response => {
                // send an authenticated request...
                console.log(response);
                vm.loggedIn = true;
            })
            .catch(response => {
                console.log(response);
            });
        }
    }
}
</script>
<style scoped>
</style>