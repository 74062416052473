<template>
    <div class="h-100">
        <b-container fluid class="h-100 pl-0 pr-0">
            <b-alert v-if="error !== null" :show="error !== null" variant="danger" class="small">            
                <strong>Google-Kalender Fehler</strong><br/>
                {{ error.code }} - {{ error.status }}<br/>            
                {{ error.message }}<br/>            
            </b-alert>            
            <kc-calendar-select v-show="calendarId === null && !loadingEvents" v-model="calendarId" class="mt-2"/>
            <div v-if="currentEvents.length > 0">
                <b-container fluid v-for="event in currentEvents" v-bind:key="event.date" class="text-center">
                    <img v-if="event.summary.includes('grau')" src="../assets/tonne_grau.png" style="width: 240px; height: 240px;"/>
                    <img v-if="event.summary.includes('blau')" src="../assets/tonne_blau.png" style="width: 240px; height: 240px;"/>
                    <img v-if="event.summary.includes('gelb')" src="../assets/tonne_gelb.png" style="width: 240px; height: 240px;"/>
                    <img v-if="event.summary.includes('braun')" src="../assets/tonne_braun.png" style="width: 240px; height: 240px;"/><br/>
                    <b-row class="align-items-center text-white bg-dark text-white p-2 rounded">
                        <b-col cols="*" style="font-size: 3rem;">
                            <b-icon icon="exclamation-triangle" class="ml-2"/>
                        </b-col>
                        <b-col>
                            <div v-if="event.start.date === $moment().format('YYYY-MM-DD')" class="small">Heute wird abgeholt</div>
                            <div v-if="event.start.date === $moment().add(2, 'days').format('YYYY-MM-DD')" class="small">Morgen wird abholt</div>
                            <div>{{ event.summary }}</div>
                        </b-col>
                    </b-row>                    
                    <b-button class="mt-2" variant="success" size="lg" @click="confirm(event)"><b-icon icon="check"/> Ich habe die Tonne rausgestellt</b-button>
                </b-container>
            </div>
            <div v-if="currentEvents.length === 0" class="small text-center mt-3">
                <b-icon icon="info-circle"/><br/>
                <span>Aktuell muss keine Tonne rausgestellt werden.</span>
            </div>
            <div v-if="futureEvents.length > 0" class="mt-5">
                <h2>Nächste Termine</h2>
                <b-container fluid>
                    <b-row v-for="event in futureEvents" v-bind:key="event.id" class="align-items-center border border-dark small border-top-0 border-left-0 border-right-0 border-bottom-0 p-2">
                        <b-col cols="*">
                            <img v-if="event.summary.includes('grau')" src="../assets/tonne_grau.png" style="width: 32px; height: 32px;"/>
                            <img v-if="event.summary.includes('blau')" src="../assets/tonne_blau.png" style="width: 32px; height: 32px;"/>
                            <img v-if="event.summary.includes('gelb')" src="../assets/tonne_gelb.png" style="width: 32px; height: 32px;"/>
                            <img v-if="event.summary.includes('braun')" src="../assets/tonne_braun.png" style="width: 32px; height: 32px;"/>
                        </b-col>
                        <b-col>
                            <span>{{ $moment(event.start.date).format('ddd DD.MM.YYYY') }} - {{ event.summary }}</span>
                        </b-col>
                    </b-row>
                </b-container>
            </div>      
        </b-container>
    </div>
</template>
<script>
import kcCalendarSelect from './calendar-select.vue';
export default {
    name: 'kc-disposal',
    components: {
        kcCalendarSelect
    },
    data() {
        return { 
            error: null,
            calendarId: null,
            loadingEvents: false,
            firstLoad: false,
            events: [],
            refreshInterval: null,
            lastRefresh: null,
            confirmedEventIds: []
        }
    },
    computed: {
        currentEvents() {
            const vm = this;
            var today = vm.$moment();
            var tomorrow = vm.$moment().add(1, 'days')
            var yesterday = vm.$moment().add(-1, 'days')
            var currentHour = vm.$moment().hour();
            return vm.events.filter(x => !vm.confirmedEventIds.includes(x.id)
                                            && (x.start.date === today.format('YYYY-MM-DD')
                                                || (x.start.date === yesterday.format('YYYY-MM-DD') && currentHour <= 5)
                                                || (x.start.date === tomorrow.format('YYYY-MM-DD') && currentHour >= 15)));

        },
        futureEvents() {
            const vm = this;
            var events = vm.events.filter(x => !vm.currentEvents.includes(x));
            return events.slice(0, 8);
        },
        bgVariant(event) {
            if (event.summary.includes('gelb')) return "warning";
            return "dark";
        }
    },
    watch: {
        calendarId() {
            const vm = this;
            localStorage["kc.disposalCalendarId"] = vm.calendarId;        
            if (this.calendarId !== null) {
                vm.loadEvents();
                vm.startRefresh();
            }
        }
    },
    created() {
        const vm = this;
        if (!vm.$gapi.isSignedIn()) {
            vm.$gapi.login().then(() => {
                vm.init();
            });            
        } else {
            vm.init();
        }
    },
    methods: {
        init() {
            const vm = this;
            if (localStorage["kc.disposalCalendarId"] !== undefined && localStorage["kc.disposalCalendarId"] !== null) {
                vm.calendarId = localStorage["kc.disposalCalendarId"];
            }            
        },
        loadEvents() {
            const vm = this;
            vm.error = null;        
            vm.loadingEvents = true;
            var startDate = vm.$moment().add(1, 'days').toDate().toISOString();
            var endDate = vm.$moment().add(360, 'days').toDate().toISOString();
            vm.$gapi.getGapiClient().then((gapi) => {
                gapi.client.calendar.events.list({
                    calendarId: vm.calendarId,
                    timeMin: startDate,
                    timeMax: endDate,
                    showDeleted: false,
                    singleEvents: true,
                    maxResults: 2500,
                    orderBy: 'startTime'                
                })
                .then(response => {
                    vm.loadingEvents = false;
                    vm.firstLoad = false;
                    vm.events = response.result.items;
                })
                .catch(response => {
                    vm.loadingEvents = false;
                    vm.firstLoad = false;
                    vm.error = response.result.error;
                });                
            });
        },
        startRefresh() {
            const vm = this;
            if (vm.refreshInterval !== null) {
                vm.stopRefresh();
            }
            vm.refreshInterval = setInterval(function() {
                if (!vm.loadingEvents) {
                    vm.loadEvents();
                    vm.lastRefresh = vm.$moment();
                }
            }, 10000);
        },
        stopRefresh() {
            const vm = this;
            if (vm.refreshInterval) {
                clearInterval(vm.refreshInterval);
            }
        },        
        confirm(event) {
            const vm = this;
            if (!vm.confirmedEventIds.includes(event.id)) vm.confirmedEventIds.push(event.id);
        }
    }
}
</script>
<style lang="sass" scoped>
</style>