import Vue from 'vue'
import VueGapi from 'vue-gapi'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './styles.scss';
import moment from 'moment'

Vue.config.productionTip = false

// Register moment
moment.locale('de');
Vue.prototype.$moment = moment;

// Google API
Vue.use(VueGapi, {
  apiKey: 'AIzaSyDljbGFl-pzj40kUdmi0Lz9Ui9j7bCJu_I',
  clientId: '247656073828-dgobhffr7q46kv2kfuc9fk2p1k5ee435.apps.googleusercontent.com',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
    'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'
  ],
  scope: 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/gmail.send'
})

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
  render: h => h(App),
}).$mount('#app')
