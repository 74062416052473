<template>
    <div>
        <b-alert v-if="error !== null" :show="error !== null" variant="danger" class="small">            
            <strong>Google-Kalender Fehler</strong><br/>
            {{ error.code }} - {{ error.status }}<br/>            
            {{ error.message }}<br/>            
        </b-alert>
        <div v-if="availableCalendars !== null && availableCalendars.length > 0" class="text-center small">
            <b-alert varian="info" show class="mb-2">
                <div class="mb-2">Wählen Sie einen Kalender:</div>
                <hr/>
                <div v-for="cal in availableCalendars" v-bind:key="cal.id">
                    <b-button variant="primary" class="mb-1" @click="selectCalendar(cal.id)">{{ cal.summary }}</b-button>
                </div>                
            </b-alert>
        </div>
    </div>
</template>
<script>
export default {
    name: 'kc-calendar-select',
    data() {
        return {   
            error: null,
            availableCalendars: []         
        }
    },
    props: {
        value: null
    },
    computed: {
        calendarId: {
            get() {
                const vm = this;
                return vm.value;
            },
            set(v) {
                const vm = this;
                if (v === null) {
                    vm.loadAvailableCalendars();
                }
                vm.$emit('input', v);
            }
        }
    },
    created() {
        const vm = this;
        vm.loadAvailableCalendars();
    },
    methods: {
        loadAvailableCalendars() {
            const vm = this;
            vm.error = null;
            vm.availableCalendars = [];
            vm.$gapi.getGapiClient().then((gapi) => {
                gapi.client.calendar.calendarList.list({
                    showDeleted: false,
                    showHidden: false,
                    maxResults: 50
                })
                .then(response => {
                    vm.availableCalendars = response.result.items;
                })
                .catch(response => {
                    vm.error = response.result.error;
                });
            });
        },
        selectCalendar(id) {
            const vm = this;
            vm.calendarId = id;
        }           
    }
}
</script>
<style lang="sass" scoped>
</style>