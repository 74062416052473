<template>
    <b-modal
        centered
        v-model="visible"
        title="Eintrag von Einkaufliste entfernen"
        header-bg-variant="primary"
        header-text-variant="light"
        footer-bg-variant="light"
        body-bg-variant="light"
        button-size="lg"
        ok-title="Ja"
        ok-variant="success"
        cancel-title="Nein"
        cancel-variant="danger"
        @ok="ok"
        @cancel="cancel">
        <slot/>
    </b-modal>    
</template>
<script>
export default {
    name: 'kc-confirm',
    data() {
        return {            
        }
    },
    props: {
        value: null,
        title: {
            type: String,
            default: null
        }
    },
    computed: {
        visible: {
            get() {
                const vm = this;
                return vm.value;
            },
            set(v) {
                const vm = this;
                vm.$emit('input', v);
            }
        }
    },
    methods: {
        ok() {
            const vm = this;
            vm.$emit('ok');
        },
        cancel() {
            const vm = this;
            vm.$emit('cancel');
        }
    }
}
</script>
<style lang="sass" scoped>
</style>