<template>
    <b-container fluid class="h-100">
        <b-row class="mt-3">
            <b-col class="text-center">
                <span v-for="m in media" v-bind:key="m.code">
                    <img v-if="m.code === 'netflix'" src="../assets/netflix.png" :alt="m.name" @click="select(m)" class="border border-dark mr-2 mb-2" role="button"/>
                    <img v-if="m.code === 'youtube'" src="../assets/youtube.png" :alt="m.name" @click="select(m)" class="border border-dark mr-2 mb-2" role="button"/>
                </span>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'kc-media',
    data() {
        return {
            mediaWindow: null,
            media: [
                { code: 'netflix', name: 'Netflix', url: 'http://www.netflix.de' },
                { code: 'youtube', name: 'YouTube', url: 'http://www.youtube.de' }
            ]
        }
    },
    methods: {
        select(m) {
            let vm = this;
            if (vm.mediaWindow !== null) {
                vm.mediaWindow.close();
            }
            vm.mediaWindow = window.open(m.url, 'KC_Media_' + m.name, 'modal=yes');
            vm.mediaWindow.focus();
        }
    }
}
</script>
<style scoped>
</style>