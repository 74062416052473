<template>
    <b-container fluid>
        <digital-clock :blink="true" :displaySeconds="false"/>        
    </b-container>
</template>
<script>
import DigitalClock from "vue-digital-clock";
export default {
    name: 'kc-clock',
    components: {
        DigitalClock
    },
    data() {
        return {            
        }
    }
}
</script>
<style scoped>
</style>
